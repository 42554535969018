import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, opacitySkeleton, breakpoints, hexToRGBA } from '@ecommerce/shared'
import SkeletonItem from './SkeletonItem'

const { cssPrefix, toPrefix } = toCssPrefix('NewsSkeleton__')

const Wrapper = styled.div`
  padding: 0;
  width: fit-content;
  margin: 53px auto 0;
  display: flex;
  flex-direction: column;
  .${cssPrefix} {
    &image {
      background: ${({ theme }) => hexToRGBA(theme.colors.black60, 0.5)};
      animation: ${opacitySkeleton} 1s linear infinite forwards;
      margin-bottom: 27px;
    }

    &title {
      background: ${({ theme }) => hexToRGBA(theme.colors.black40, 0.25)};
    }
    &info {
      background: ${({ theme }) => hexToRGBA(theme.colors.black40, 0.25)};
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    flex-direction: row;
    width: auto;
    justify-content: space-between;
    padding: 0 29px;
    max-width: 1284px;
    .${cssPrefix} {
      &image {
        height: 292px;
        width: 51%;
      }
    }
    .content-info {
      width: 45%;
    }
  }
`

const NewsSkeleton = () => (
  <Wrapper data-testid="news-skeleton">
    <SkeletonItem className={toPrefix('image')} width="259px" height={162} margin="0 0 14px 0" />
    <div className="content-info">
      <SkeletonItem className={toPrefix('title')} width="100%" height={23} margin="0 0 10px 0" />
      <SkeletonItem className={toPrefix('title')} width="70%" height={23} margin="0 0 28px 0" />
      <SkeletonItem className={toPrefix('info')} width="257px" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="100%" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="100%" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="100%" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="100%" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="100%" height={11} margin="0 0 16px 0" />
      <SkeletonItem className={toPrefix('info')} width="50%" height={11} margin="0" />
    </div>
  </Wrapper>
)

export default NewsSkeleton
