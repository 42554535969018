import React from 'react'
import useQuery from '../../hooks/useQuery'
import { BrandsGrid } from './BrandsGrid'

type Props = {
  listId: string
}

const query = `query BrandsList($listId: String!) {
    orList(id: $listId) {
      title
      itemsCollection {
        items {
          ... on Brand {
            id
            image {
              url
            }
          }
        }
      }
    }
  }`

export type QueryResult = {
  orList: {
    title: string
    itemsCollection: {
      items: Array<{
        id: string
        image: {
          url: string
        }
      }>
    }
  }
}

const Brands = ({ listId }: Props) => {
  const { data } = useQuery<QueryResult>(query, { variables: { listId } })
  const list = data?.orList
  const brands = list?.itemsCollection?.items?.map((item) => item.image.url)

  return <BrandsGrid brands={brands} title={data?.orList.title} />
}

export default Brands
