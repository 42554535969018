import React from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints } from '@ecommerce/shared'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { RichTextJson, ContentfulMLVideo, FileType } from '../../graphql/contentfulTypes'
import Video from '../Video'

const { cssPrefix, toPrefix } = toCssPrefix('NewsCard__')

type StyleProps = {
  alignment?: string
  isVideo?: boolean
  backgroundColor?: string
  textColor?: string
}

const Wrapper = styled.div<StyleProps>`
  padding: ${({ isVideo }) => (isVideo ? '0' : '0 29px')};
  .${cssPrefix} {
    &card {
      display: flex;
      justify-content: space-between;
      background: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.white};
      flex-direction: column;
      border-radius: ${({ isVideo, theme }) => (isVideo ? '0' : theme.borderRadius)};
      color: ${({ textColor, theme }) => textColor ?? theme.colors.black};
      max-width: ${({ isVideo }) => (isVideo ? 'none' : '317px')};
      margin: 53px auto 0;
      &-info {
        padding: 0 29px 32px;
        text-align: left;
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
          margin: 0;
        }
        .title {
          font-size: 24px;
          line-height: 31px;
          font-weight: bold;
          margin-bottom: 24px;
        }
        .description {
          font-size: 24px;
          font-weight: 400;
          line-height: 31px;
          letter-spacing: 0.11142856627702713px;
        }
      }
      &-content-image {
        padding: ${({ isVideo }) => (isVideo ? '0' : '32px 29px 0')};
        margin-bottom: 24px;
        img {
          border-radius: ${({ theme }) => theme.borderRadius};
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &card {
        ${({ alignment, isVideo }) =>
          !isVideo && (alignment === 'left' ? ' flex-direction: row;' : 'flex-direction: row-reverse;')};
        ${({ isVideo }) => !isVideo && 'max-width: 710px;'}
        justify-content: space-between;
        padding: ${({ isVideo }) => (isVideo ? '0' : '32px')};
        margin-top: 32px;
        &-info {
          ${({ isVideo }) => !isVideo && 'width: 45%;'}
          ${({ isVideo }) => !isVideo && 'padding: 0;'}
        }
        &-content-image {
          ${({ isVideo }) => !isVideo && 'width: 51%;'}
          ${({ isVideo }) => !isVideo && 'padding: 0;'}
        max-width: none;
          max-height: none;
          margin: 0;
          display: flex;
          align-items: center;
          .video {
            height: 354px;
            width: 100%;
            object-fit: cover;
            border-radius: 0;
            margin-bottom: 34px;
          }
          img {
            height: 354px;
            width: 100%;
            object-fit: cover;
            border-radius: 0;
          }
        }
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    padding: 0 29px;
    .${cssPrefix} {
      &card {
        border-radius: ${({ theme }) => theme.borderRadius};
        flex-direction: ${({ alignment }) => (alignment === 'left' ? 'row' : 'row-reverse')};
        max-width: 1284px;
        padding: ${({ isVideo }) => (isVideo ? '56px 38px' : '56px 60px')};
        &-info {
          padding: 0;
          margin: 0;
          width: 47%;
        }
        &-content-image {
          margin: 0;
          width: 48%;
          .video {
            margin: 0;
          }
          img {
            height: 292px;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`

type Props = {
  newData: {
    title: string
    imageAlignment: string
    backgroundCardColor?: string
    textColor?: string
    description: RichTextJson
    descriptionImage?: FileType | null
    descriptionVideo?: ContentfulMLVideo | null
  }
}

const NewsCard = ({ newData }: Props) => {
  const {
    title,
    description,
    descriptionImage: image,
    imageAlignment,
    descriptionVideo: video,
    backgroundCardColor,
    textColor,
  } = newData
  return (
    <Wrapper alignment={imageAlignment} isVideo={!!video} backgroundColor={backgroundCardColor} textColor={textColor}>
      <div className={toPrefix('card')}>
        <div className={toPrefix('card-content-image')}>
          {video ? (
            <Video
              src={video.video.url}
              poster={video.videoPoster?.url}
              title={video.title}
              description={video.videoDescription}
              className="video"
              preload="none"
            />
          ) : (
            <img src={image?.url} alt="" />
          )}
        </div>
        <div className={toPrefix('card-info')}>
          {title && <div className="title">{title}</div>}
          {description && <div className="description">{documentToReactComponents(description.json)}</div>}
        </div>
      </div>
    </Wrapper>
  )
}

export default NewsCard
