import React, { useState } from 'react'
import styled from 'styled-components'
import { toCssPrefix, breakpoints, SelectOption, Select } from '@ecommerce/shared'
import { AnimatePresence, motion } from 'framer-motion'
import useQuery from '../../hooks/useQuery'
import { Establishment } from './Establishment'
import EstablishmentSkeleton from '../Skeletons/EstablishmentSkeleton'

const { cssPrefix, toPrefix } = toCssPrefix('EstablishmentsWrapper__')

type Props = {
  listId: string
}

const Wrapper = styled.div`
  .${cssPrefix} {
    &select {
      max-width: 317px;
      margin: 0 auto 40px;
      text-transform: capitalize;
      .Select__label {
        color: ${({ theme }) => theme.colors.white};
        text-align: left;
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &select {
        margin-bottom: 56px;
      }
      &content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &select {
        max-width: 319px;
      }
      &content {
        grid-template-columns: repeat(4, 1fr);
        max-width: 1030px;
        margin: 0 auto;
      }
    }
  }
`

const query = `query Establishments($listId: String!) {
  orList(id: $listId) {
    title
    itemsCollection {
      items {
        ... on OrEstablishment {
          name
          title
          description
          region
          city
          schedule
          address
          image {
            url
          }
          localImage {
            url
          }
          catalog {
            url
          }
        }
      }
    }
  }
}`

export type QueryResult = {
  orList: {
    title: string
    itemsCollection: {
      items: Array<{
        name: string
        title: string
        description: string
        region: string
        city: string
        schedule: string
        address: string
        image: {
          url: string
        }
        localImage: {
          url: string
        }
        catalog: {
          url: string
        }
      }>
    }
  }
}

const defaultSelectValue = {
  label: 'Todas',
  value: 'all',
}

const Establishments = ({ listId }: Props) => {
  const { data, loading, error } = useQuery<QueryResult>(query, { variables: { listId } })
  const establishments = data?.orList?.itemsCollection?.items
  const regions = establishments?.map((option) => option.region.toLowerCase())
  const [activeFilter, setActiveFilter] = useState(defaultSelectValue.value)

  const filtersData: SelectOption[] = regions
    ? regions
        .filter((option, index) => regions.indexOf(option) === index)
        .map((option) => ({
          value: option,
          label: option,
        }))
    : []

  const handleRegionSelect = (value: string) => {
    setActiveFilter(value)
  }

  return (
    <Wrapper data-testid="establishments">
      <div className={toPrefix('header')}>
        <h2>{data?.orList?.title}</h2>
      </div>
      <Select
        className={toPrefix('select')}
        label="Región"
        placeholder={defaultSelectValue.label}
        options={[defaultSelectValue, ...filtersData]}
        onSelect={handleRegionSelect}
        status={!error ? undefined : 'error'}
        errorMessage={!error ? undefined : 'ha ocurrido un error al cargar las regiones'}
      />
      <div className={toPrefix('content')}>
        {loading ? (
          <>
            {Array(8)
              .fill(0)
              .map((_, i) => (
                <EstablishmentSkeleton key={i} />
              ))}
          </>
        ) : (
          <>
            <AnimatePresence>
              {establishments?.map((item, index) => {
                if (
                  activeFilter &&
                  item.region.toLowerCase() !== activeFilter &&
                  activeFilter !== defaultSelectValue.value
                )
                  return null
                return (
                  <motion.div
                    key={`${item.title}-${index}`}
                    initial={{ opacity: 0, display: 'block' }}
                    animate={{ opacity: 1, transition: { duration: 0.5 } }}
                    exit={{ opacity: 0, display: 'none' }}
                  >
                    <Establishment
                      key={`${item.title}-${index}`}
                      title={item.title}
                      description={item.description}
                      city={item.city}
                      image={item.image?.url}
                      schedule={item.schedule}
                      address={item.address}
                      localImage={item.localImage?.url}
                      catalog={item.catalog?.url}
                    />
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </>
        )}
      </div>
    </Wrapper>
  )
}

export default Establishments
