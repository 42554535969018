import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  toCssPrefix,
  breakpoints,
  TextField,
  Select,
  TextArea,
  sendCustomEmail,
  vamosXOtraContactParams,
  Button,
  showToast,
} from '@ecommerce/shared'
import * as Yup from 'yup'
import { Controller, useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { vamosXOtraRegions } from '../../templates/vamosxotra/SectionWrapper'
import secrets from '../../config/secrets'

const { cssPrefix, toPrefix } = toCssPrefix('Contact__')

const Wrapper = styled.div`
  padding: 32px 29px;
  max-width: 416px;
  margin: 0 auto;

  .${cssPrefix} {
    &header {
      color: ${({ theme }) => theme.colors.bodyText};
      .title {
        font-size: 24px;
        margin-bottom: 32px;
        line-height: 31px;
        font-weight: bold;
      }
      .description {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 24px;
      }
    }
    &form {
      .content-left {
        width: 100%;
      }
      .content-right {
        width: 100%;
      }
      .field {
        text-align: left;
        margin-bottom: 24px;
        label {
          font-size: 13px;
          color: ${({ theme }) => theme.colors.bodyText};
        }
      }
      .text-area {
        textarea {
          height: 284px;
        }
      }
      .button {
        border: none;
        cursor: pointer;
        background: ${({ theme }) => theme.colors.white};
        padding: 7.5px 58.5px;
        font-size: 13px;
        line-height: 18px;
        color: ${({ theme }) => theme.colors.black};
        font-weight: bold;
        border-radius: ${({ theme }) => theme.borderRadius};
        div div {
          &:after {
            background: ${({ theme }) => theme.colors.black};
          }
        }
      }
    }
  }
  @media (${breakpoints.tabletPortrait.min}) {
    max-width: 897px;
    padding: 32px 0;
    .${cssPrefix} {
      &header {
        .title {
          margin-bottom: 75px;
        }
        .description {
          text-align: left;
          margin-bottom: 32px;
        }
      }
      &form {
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        .content-left {
          max-width: 416px;
        }
        .content-right {
          max-width: 416px;
        }
      }
    }
  }
`

const defaultValues: vamosXOtraContactParams = {
  localName: '',
  address: '',
  region: '',
  contact: '',
  email: '',
  description: '',
}

const ContactForm = () => {
  const [loading, setLoading] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState('')
  const [description, setDescription] = useState('')
  const methods = useForm<vamosXOtraContactParams>({
    resolver: yupResolver(
      Yup.object().shape({
        localName: Yup.string().required('Debes completar este campo'),
        address: Yup.string().required('Debes completar este campo'),
        region: Yup.string().required('Debes completar este campo'),
        contact: Yup.string()
          .required('Debes completar este campo')
          .matches(/^[A-z\s]+$/, 'solo caracteres alfabeticos'),
        email: Yup.string().email('El formato del email no es el correcto').required('Debes completar este campo'),
        description: Yup.string()
          .required('Debes completar este campo')
          .max(300, (max) => `No debe exceder los ${max.max} caracteres`),
      }),
    ),
    defaultValues,
    mode: 'all',
  })

  const { control, reset, register, setValue, formState, trigger, getValues, handleSubmit } = methods

  const { dirtyFields, isValid, errors } = formState

  useEffect(() => {
    register({ name: 'region' })
  }, [register])

  const { COUNTRY } = secrets

  const message = `
    <div>
      <h3>Email:</h3>
      <p>${getValues().email}</p>
      <h3>Nombre del Local:</h3>
      <p>${getValues().localName}</p>
      <h3>Dirección:</h3>
      <p>${getValues().address}</p>
      <h3>Contacto:</h3>
      <p>${getValues().contact}</p>
      <h3>Región:</h3>
      <p>${vamosXOtraRegions.find((region) => region.id === getValues().region)?.name}</p>
      <h3>Descripción del Local:</h3>
      <p>${getValues().description}</p>
    </div>
  `

  const resetValues = () => {
    setDescription('')
    setSelectedRegion('')
    reset()
  }

  const onSubmit = async () => {
    setLoading(true)
    if (!(Object.keys(dirtyFields).length === 0) && isValid)
      try {
        await sendCustomEmail({
          message,
          subject: 'Contact - Vamos x Otra',
          country: COUNTRY,
        })
        resetValues()
        showToast({ content: 'Email enviado exitosamente!', type: 'success' })
      } catch (e) {
        showToast({ content: 'ha ocurrido un error', type: 'error' })
      }
    setLoading(false)
  }

  const onRegionChange = async (selected: string) => {
    setSelectedRegion(selected.valueOf().toString())
    setValue('region', selected.valueOf().toString(), { shouldDirty: true })
    await trigger('region')
  }

  const onDescriptionChange = async (text: string) => {
    setDescription(text.valueOf().toString())
    setValue('description', text.valueOf().toString(), { shouldDirty: true })
    await trigger('description')
  }

  return (
    <Wrapper>
      <div className={toPrefix('header')}>
        <div className="title">Contacto</div>
        <div className="description">Completa los datos de tu local</div>
      </div>
      <div className={toPrefix('form')}>
        <FormProvider {...methods}>
          <div className="content-left">
            <Controller
              control={control}
              name="localName"
              render={({ value, onChange }) => (
                <TextField
                  errorMessage={errors.localName?.message}
                  status={!errors.localName ? undefined : 'error'}
                  value={value}
                  onChange={onChange}
                  label="Nombre local"
                  disabled={false}
                  className="field"
                />
              )}
            />
            <Controller
              control={control}
              name="address"
              render={({ value, onChange }) => (
                <TextField
                  errorMessage={errors.address?.message}
                  status={!errors.address ? undefined : 'error'}
                  value={value}
                  onChange={onChange}
                  label="Dirección local"
                  disabled={false}
                  className="field"
                />
              )}
            />
            <Controller
              control={control}
              name="region"
              render={() => (
                <Select
                  className="field"
                  label="Región"
                  status={!errors.region ? undefined : 'error'}
                  errorMessage={errors.region?.message}
                  key={selectedRegion}
                  placeholder={vamosXOtraRegions.find((region) => region.id === selectedRegion)?.name || 'Ciudad'}
                  onSelect={(selected) => onRegionChange(selected)}
                  options={vamosXOtraRegions.map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                />
              )}
            />
            <Controller
              control={control}
              name="contact"
              render={({ value, onChange }) => (
                <TextField
                  errorMessage={errors.contact?.message}
                  status={!errors.contact ? undefined : 'error'}
                  value={value}
                  onChange={onChange}
                  label="Nombre de contacto"
                  disabled={false}
                  className="field"
                />
              )}
            />
          </div>
          <div className="content-right">
            <Controller
              control={control}
              name="email"
              render={({ value, onChange }) => (
                <TextField
                  errorMessage={errors.email?.message}
                  value={value}
                  onChange={onChange}
                  label="Correo electrónico"
                  disabled={false}
                  className="field"
                  status={!errors.email ? undefined : 'error'}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={() => (
                <TextArea
                  errorMessage={errors.description?.message}
                  status={!errors.description ? undefined : 'error'}
                  value={description}
                  onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                  label="Sobre tu local"
                  disabled={false}
                  className="field text-area"
                  placeholder="Sobre el local"
                />
              )}
            />
            <Button isLoading={loading} className="button" type="submit" onClick={handleSubmit(onSubmit)}>
              Enviar
            </Button>
          </div>
        </FormProvider>
      </div>
    </Wrapper>
  )
}

export default ContactForm
