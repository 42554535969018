import React, { useMemo } from 'react'
import {
  toCssPrefix,
  Loading,
  breakpoints,
  Market,
  getStoredDistributionCenter,
  DistributionCenter,
} from '@ecommerce/shared'
import styled from 'styled-components'
import useQuery from '../../hooks/useQuery'
import Promotions from '../home/Promotions'
import { ClientSideBlock, BlockType } from '../../graphql/contentfulTypes'
import { BannerSliderWidget } from '../../components/BannerSlider'
import Steps from '../../components/Steps'
import Brands from '../../components/Brands/index'
import SkuList from '../home/SkuList'
import { useCartStockErrorHandler } from '../../utils/errors'
import { extractBlocksSkus } from '../utils'
import useAlgoliaFetch from '../../hooks/useAlgoliaFetch'
import ClientSideRecipeList from '../../components/Recipes/ClientSideRecipeList'
import Establishments from '../../components/Establishments'
import ContactForm from '../../components/ContactForm'
import NewsCards from '../../components/News/NewsCards'

const { cssPrefix, toPrefix, toPrefixArray } = toCssPrefix('SectionWrapper__')

type Props = {
  pageId: string
  currentMarket: Market
  currentDistributionCenter: DistributionCenter
  themeId?: string
}

type BlocksQueryResult = {
  pgPage: {
    blocksCollection?: {
      items?: ClientSideBlock[]
    }
  }
}

const Wrapper = styled.div`
  text-align: center;
  min-height: 100vh;
  .${cssPrefix} {
    &loader {
      min-height: 100vh;
    }
    &section {
      margin-top: 40px;
      .text-align {
        h2 {
          text-align: center;
        }
        p {
          text-align: left;
        }
      }
    }
    &banner-slider {
      padding: 0 0 0 20px;
    }
    @media (${breakpoints.desktop.min}) {
      &banner-slider {
        padding: 0 90px;
      }
    }
  }
`

const query = `
query VamosPorOtraSections($id: String!) {
	pgPage(id: $id) {
  	name
    blocksCollection {
      items {
        title
        name
        blockType
        sys {
          id
        }
        itemsCollection {
          items {
            ... on Product {
              sku
            }
            ... on Bundle {
              sku
            }
          }
        }
      }
    }
  }
}`

export const vamosXOtraRegions = [
  { id: 'arica', name: 'Arica y Parinacota' },
  { id: 'tarapaca', name: 'Tarapacá' },
  { id: 'valparaiso', name: 'Valparaiso' },
  { id: 'maule', name: 'El Maule' },
  { id: 'biobio', name: 'Bío-bío' },
  { id: 'ñuble', name: 'Ñuble' },
  { id: 'araucania', name: 'La Araucanía' },
  { id: 'lagos', name: 'Los Lagos' },
]

export const SectionWrapper = ({ pageId, themeId, currentDistributionCenter }: Props) => {
  const storedDistributionCenter = getStoredDistributionCenter() || currentDistributionCenter
  const { data } = useQuery<BlocksQueryResult>(query, { variables: { id: pageId } })
  const blocks = data?.pgPage?.blocksCollection?.items ?? []

  const skus = useMemo(() => extractBlocksSkus(blocks ?? []), [blocks])

  const { products, loadingProducts } = useAlgoliaFetch({
    distributionCenter: storedDistributionCenter,
    skus,
    navigationState: { themeId },
  })

  const stockErrorHandler = useCartStockErrorHandler()

  return (
    <Wrapper>
      {data ? (
        blocks.map((block) => {
          const { id } = block.sys
          switch (block.blockType) {
            case BlockType.PROMO_LARGE:
              return (
                <div key={id} className={toPrefixArray(['section', 'banner-slider'])}>
                  <Promotions listId={id} themeId={themeId} />
                </div>
              )

            case BlockType.BANNER_SLIDER: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <BannerSliderWidget listId={id} />
                </div>
              )
            }

            case BlockType.TEXT_WITH_ICON: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <Steps listId={id} />
                </div>
              )
            }

            case BlockType.BRAND: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <Brands listId={id} />
                </div>
              )
            }

            case BlockType.RECIPE: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <ClientSideRecipeList listId={id} className="text-align" themeId={themeId} />
                </div>
              )
            }

            case BlockType.ESTABLISHMENT: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <Establishments listId={id} />
                </div>
              )
            }

            case BlockType.PRODUCT: {
              return (
                <div key={id} className={toPrefix('section')}>
                  <SkuList
                    loadingProducts={loadingProducts}
                    pageProducts={products}
                    key={block.name}
                    listId={id}
                    stockErrorHandler={stockErrorHandler}
                    themeId={themeId}
                  />
                </div>
              )
            }

            case BlockType.NEWS: {
              return <NewsCards key={block.name} listId={id} />
            }

            case BlockType.CONTACT_FORM: {
              return <ContactForm />
            }

            default:
              return null
          }
        })
      ) : (
        <Loading className={toPrefix('loader')} />
      )}
    </Wrapper>
  )
}
